<template>
    <div>
        <div class="col d-sm-flex justify-content-center">
            <div class="card text-center">
                <h2>Attivazione prodotto</h2>
                <p class="text-help">Inserisci il codice presente sulla tessera</p>
                <b-form-group id="code" label="" label-for="code" description="">
                    <b-form-input v-model.trim="code" class="code" autofocus></b-form-input>
                </b-form-group>

                <b-button variant="success" size="lg"  @click="redirectToActivation" :disabled="this.$store.state.loading">Procedi all'attivazione</b-button>
                <br>
                <p class="text-help">Nel prossimo passaggio ti verrà chiesto di entrare con le tue credenziali o di registrarti in caso di primo accesso.</p>

                <div class="text-center">
                    <br>
                    <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                    <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
                </div>
            </div>
        </div>
        <br>
        <p class="text-center small"><router-link to="/"><b-icon-arrow-left /> Torna al login</router-link> </p>
    </div>
</template>

<script>
const vueConfig = require("../../../vue.config.js");
import ProductLicenseDataService from "@/components/product_license/ProductLicenseDataService";
import {ParseUrl} from "@/helpers/ParseUrl"

export default {
    name: "product-license-activate-home",

    data() {
        return {
            code: null,
            query: '',
            message: '',
            errorMsg: ''
        };
    },
    methods: {
        redirectToActivation() {
            if (!this.code) {
                this.errorMsg = 'Inserire il codice presente sulla tessera'
            } else {
                ProductLicenseDataService.getByCode(this.code)
                    .then(response => {
                        if (response.data.activated && response.data.profileId) this.errorMsg = "Questo prodotto risulta già attivato.";
                        else window.location.href = vueConfig.publicPath + 'activate/' + this.code.trim() + '/' + this.query;
                    })
                    .catch(e => {
                        if (e.response.status == 400) this.errorMsg = "Il codice inserito non esiste";
                        console.log(e.response);
                    });
            }
        },
    },
    mounted() {
        const parsedUrl = ParseUrl();
        this.query = parsedUrl.query;
    }
};
</script>

<style>

#code {
    display: inline-block;
    margin: 25px 0px;
}

.code {
    display: inline-block;
    font-size: 40px;
    width: 8em;
    text-align: center;
}
.code, .code:focus {
    background-color: #fdf4d7;
    border: 2px solid #f8e4a4;
}
.text-help {
    text-align: center;
    color: #6c757d;
    font-size: 80%;
    max-width: 265px;
    margin: 0 auto;
}
</style>
